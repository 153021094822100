import 'lazysizes';
import Tablesort from 'tablesort';

const html = document.documentElement;

// Pour supprimer un élément HTML
const removeNode = e => e.parentNode.removeChild( e );

const setZoom = val => {
    if ( !html.matches( ".no-delay" ) ) { html.classList.add( "no-delay" ); }
    html.style.setProperty( '--font-size', val );
    if ( html.matches( ".no-delay" ) ) {
        setTimeout( () => {
            lazySizes.autoSizer.checkElems();
            html.classList.remove( "no-delay" );
        }, 600);
    }
}

// Fonction callback pour les mobiles Safari
const setUnits = () => {

    let height = window.innerHeight;
    let width = window.innerWidth;
    let ratio = width / height;

    if ( ratio > 1.8 && !html.matches( ".wide" ) ) {
        html.classList.add( "wide" );
    }

    if ( ratio < 1.8 && html.matches( ".wide" ) ) {
        html.classList.remove( "wide" );
    }

    let vh = height * 0.01;
    html.style.setProperty( '--vh', `${ Number( vh ).toFixed( 2 ) }px` );

};

const resizePanels = () => {

    if ( !html.matches( ".resize" ) ) {
        html.classList.add( "resize" );
    }

    clearTimeout( resizeTimer );

    var resizeTimer = setTimeout( () => {

        if ( html.matches( ".resize" ) ) {
            setUnits();
            html.classList.remove( "resize" );
        }

    }, 1000 );

}

const initResize = () => {

    setUnits();
    resizePanels();
    
}

const getRem = () => {

    const style = window.getComputedStyle( html, null ).getPropertyValue( 'font-size' );
    return parseFloat( style );

}

const scrollInit = () => {

    var scrollTop = 0;

    const body = document.body;
    const container = body.querySelector( "#container" );

    html.addEventListener( "wheel", () => {

        const innerHeight = window.innerHeight;
        if ( body ) {

            const scrollHeight = body.scrollHeight;
            scrollTop = body.scrollTop + innerHeight;
            
            scrollTop > scrollHeight - 100 && !html.matches(".scroll-bottom") && html.classList.add("scroll-bottom");
            scrollTop < scrollHeight - 100  && html.matches(".scroll-bottom") && html.classList.remove("scroll-bottom");

            if (container) {

                const oneRem = getRem();

                if (container.matches(".home")) {

                    const projectLinks = container.querySelectorAll(".project__link");
                    for (let i = 0; i < projectLinks.length; i++) {
                        const projectLink = projectLinks[i];
                        const offsetTop = projectLink.offsetTop;
                        const offsetHeight = projectLink.offsetHeight;
                        const offsetBottom = offsetTop + offsetHeight;
            
                        scrollTop > offsetBottom+oneRem*2 && !projectLink.matches(".scroll-up") && projectLink.classList.add("scroll-up");
                        scrollTop < offsetBottom+oneRem*2 && projectLink.matches(".scroll-up") && projectLink.classList.remove("scroll-up");
                    }
                    
                }
                
            }
       
        }
        
    } );

}

const clickInit = () => {

    html.addEventListener( "click", e => {

        const target = e.target;

        if ( target.matches( '.menu__btn' ) ) {
            html.classList.toggle( 'menu-open' );
        }

        if ( target.matches( "#unzoom__btn" ) ) {
            var fontSize = getComputedStyle( html ).getPropertyValue( '--font-size' );
            if (fontSize) {
                fontSize > 1 && setZoom( Number( fontSize ) - 0.1 );
            }
        }
        if ( target.matches( "#zoom__btn" ) ) {
            var fontSize = getComputedStyle( html ).getPropertyValue( '--font-size' );
            if (fontSize) {
                fontSize < 2.1 && setZoom( Number( fontSize ) + 0.1 );
            }
        }

        if ( target.matches( ".view-all" ) ) {
            const parent = target.parentNode;
            const hiddens = parent.querySelectorAll( '.hidden' );
            if ( hiddens ) {
                hiddens.forEach( e => {
                    e.style.display = 'initial';
                } );
            }
            removeNode( target );
        }

    } );

}

const pageInit = () => {
    const container = html.querySelector( "#container" );
    if ( container.matches( ".home" ) ) {
        console.log(container);
    }
}

const init = () => {

    clickInit();
    scrollInit();
    pageInit();

    const table = html.querySelector( "#menu nav table" );
    if ( table ) new Tablesort( table );

    setTimeout( () => {
        
        html.classList.remove( 'loading' );

    }, 200 );

    if ( window.attachEvent ) {

        window.attachEvent( 'onresize', () => initResize() );
        
    } else if ( window.addEventListener ) {

        window.addEventListener( 'resize', () => initResize(), true );
        
    }

}

const renderInit = () => {

    setUnits();

}

document.fonts.onloadingdone = () => { renderInit() };

document.addEventListener( 'DOMContentLoaded', init, false );